/************************************************************************************
FOOTER
*************************************************************************************/

.footer {
  .f_1 {
    @include rem(padding, 50px 0);
  }

  .f_2 {
    .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include rem(padding-bottom, 20px);
    }
  }

  .foot-item {
    @include rem(margin, 20px 0);
  }

  h3 {
    @include rem(font-size, 18px);
    font-weight: $font-semibold;
    color: $blue;
    @include rem(margin-bottom, 50px);
  }

  ul {
    li {
      padding: 0;
      margin: 0;
      @include rem(padding-left, 25px);
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        @include rem(top, 9px);
        width: 10px;
        height: 10px;
        background: url('../img/arr-r.svg') center center no-repeat;
        background-size: cover !important;
      }
    }

    &.unstyled {
      li {
        padding: 0;
        background: none;

        &:before {
          content: none;
        }
      }
    }
  }

  a {
    color: #373737;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .copy {
    @include rem(font-size, 18px);
    margin: 0;
  }

  .mw {
    position: relative;
    color: #c6c6c6;
    display: block;

    &:hover {
      color: #00daee;
    }

    .text {
      position: absolute;
      left: 0;
      top: 0;
      text-indent: -80000px;
      display: block;
    }

    .icon-mw {
      width: 70px;
      height: 30px;
    }
  }

  .orwin {
    filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android */
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
    @include transition(all,.3);
    opacity: 0.5;

    &:hover {
      filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
      -webkit-filter: grayscale(0%);
      opacity: 1;
    }

    .text {
      position: absolute;
      left: 0;
      top: 0;
      text-indent: -80000px;
      display: block;
    }
  }
}