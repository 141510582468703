/************************************************************************************
MEDIA QUERIES
*************************************************************************************/

/*========================= Viewport width 1199px and lower =========================*/

@media screen and (max-width: 1199px) {
  h1, .h1 {
    @include rem(font-size, 50px);
  }

  h2.light {
    .hl {
      display: inline;
    }
  }

  .teaser-hp {
    @include rem(padding, 80px 0);
  }

  .main-nav {
    ul {
      li {
        a {
          @include rem(font-size, 16px);
        }
      }
    }
  }

  .products {
    .item {
      .text {
        @include rem(padding, 15px);
      }
    }
  }

  .categories {
    .item {
      flex: 0 0 16.66666%;
      max-width: 16.66666%;

      .img {
        @include rem(width, 70px);
        @include rem(height, 70px);
      }
    }
  }

  .box-negative {
    @include rem(max-width, 290px);
  }

  .demand-basket {
    padding: 0;

    .item {
      .num {
        position: relative;
        left: 0;
        width: auto;
        text-align: left;
        margin: 0;
      }
    }
  }

  .comparing {
    .item {
      .text {
        @include rem(padding-left, 15px);
        @include rem(padding-right, 15px);
      }

      .params {
        ul {
          li {
            @include rem(padding-left, 15px);
            @include rem(padding-right, 15px);
          }
        }
      }
    }
  }
}

/*========================= Viewport width 991px and lower =========================*/

@media screen and (max-width: 991px) {
  html {
    font-size: 15px;
  }

  h1, .h1 {
    @include rem(font-size, 40px);
  }

  h2, .h2 {
    @include rem(font-size, 40px);

    &.big {
      @include rem(font-size, 40px);
    }
  }

  h3, .h3 {
    //@include rem(font-size, 30px);
  }

  .main-nav {
    display: none;
  }

  .secondary-nav {
    height: auto;
    ul {
      li {
        .txt-1 {
          display: none;
        }

        &.li-search-trig {
          display: block;
        }

        &.li-search {
          display: none;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          @include rem(top, 100px);
          @include rem(height, 90px);
          //display: flex;
          align-items: center;
          padding-left: 15px;
          padding-right: 15px;
          background: #fff;
          overflow: hidden;

          &:after {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: -100%;
            width: 300%;
            height: 100%;
            background: #fff;
          }
        }
      }
    }
  }

  .nav-btn {
    display: block;
  }

  .header {
    .h_1 {
      .inner {
        @include rem(height, 100px);
      }
    }

    .h_2 {
      //display: none !important;
    }

    .megamenu {
      display: none !important;
    }

    .logo {
      @include rem(max-width, 80px);
    }

    .header-r {
      flex-wrap: nowrap;
    }

    form.form-search {
      width: 100%;
         
      .cell {
        max-width: 100% !important;
        width: 100% !important;
      }
  
      input.text {
        border: 2px solid #ddddde;
      }
    }

    .secondary-nav {
      height: auto;
      ul {
        li {
          &.li-search {
            background: transparent;
            &:after {
              background: transparent;
            }
          }
        }
      }
    }

    &.search-opened {
      .h_1 {
        @include rem(margin-bottom, 90px);
      }

      ul {
        li {
          &.li-search {
            display: flex;
            animation: rotateMenu 400ms ease-in-out forwards;
            transform-origin: top center;
          }
        }
      }

      .dropdown-search {
        display: none;

        &.active {
          display: block;
        }
      }
    }
  }

  .teaser-hp {
    @include rem(padding, 50px 0);
  }

  .mobile-nav-wrap {
    display: block;
  }

  .mobile-nav {
    ul {
      li {
        a {
          max-width: 690px;
          display: block;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }

  .slider-nav {
    .prev {
      left: -20px;
      @include rem(width, 50px);
      @include rem(height, 50px);
    }

    .next {
      right: -20px;
      @include rem(width, 50px);
      @include rem(height, 50px);
    }
  }

  .block {
    @include rem(padding, 60px 0);

    &.narrow {
      @include rem(padding, 40px 0);
    }

    &.alpha {
      padding-top: 0;
    }

    &.omega {
      padding-bottom: 0;
    }
  }

  .posts {
    .head {
      @include rem(margin-bottom, 50px);
    }
  }

  .category-filter {
    justify-content: flex-start;
    .category-filter-item {
      padding-left: 32px;
      margin: 0.5rem 0.8rem;  
      &:before {
        width: 22px;
        height: 22px;
        border-radius: 5px;
      }
    }
  }

  .references {
    .item {
      .text {
        display: block;
        @include rem(padding, 30px);

        .btn-set {
          justify-content: flex-start;
        }
      }
    }
  }

  .entry {
    .table-wrap {
      overflow-x: auto;
    }
  }

  .footer {
    .f_1 {
      @include rem(padding, 60px 0);
    }
  }

  .contact-us {
    .box {
      @include rem(padding, 40px);
    }
  }

  .navigator {
    ol {
      li {
        @include rem(font-size, 25px);
      }
    }
  }

  .product-detail {
    .img {
      order: 0;
      @include rem(margin-bottom, 30px);
    }

    div.text {
      order: 1;
      @include rem(margin-bottom, 30px);
    }
  }

  .box-negative {
    margin-top: 0;
    //@include rem(max-width, 450px);
    max-width: 100%;
  }

  .box-fixer {
    @include rem(margin-top, 0);
  }

  .fixer {
    position: relative !important;
  }

  .comparing {
    .item-list {
      margin-left: -5px;
      margin-right: -5px;
      flex-wrap: nowrap;
    }

    .item {
      padding: 0 5px;
      max-width: 200px;
      flex: 0 0 200px;
      flex-shrink: 0;

      &.item-labels {
        max-width: 200px;
        flex: 0 0 200px;
      }
    }
  }

  .product-filter {
    .col {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
  }

  .col-2-l-set {
    flex-wrap: wrap;

    .column-1 {
      flex: 0 0 100%;
      max-width: 100%;
      @include rem(margin-bottom, 30px);
      //@include rem(flex, 0 0 250px);
      //@include rem(max-width, 250px);
    }
  }

  .product-nav {
    h3 {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        @include rem(right, 28px);
        @include rem(top, 23px);
        @include rem(width, 15px);
        @include rem(height, 15px);
        border-left: 2px solid #fff;
        border-bottom: 2px solid #fff;
        @include rotate(-45deg);
        @include transition(all, .3);
      }
    }

    ul, form {
      display: none;
    }

    &.active {
      h3 {
        &:after {
          @include rotate(135deg);
          @include rem(top, 30px);
        }
      }

      & > ul, & > form {
        display: block;
      }
    }
  }
}

/*========================= Viewport width 767px and lower =========================*/

@media screen and (max-width: 767px) {
  html {
    font-size: 14px;
  }

  .secondary-nav {
    height: auto;

    /*.li-search {
      //display: none;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      @include rem(top, 100px);
      padding: 10px 15px;
      background: #fff;
      overflow: hidden;

      &:after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: -100%;
        width: 300%;
        height: 100%;
        background: #fff;
      }
    }*/
  }

  .references {
    .item-inner {
      height: auto;
      padding-bottom: 0;
    }
  }

  .gallery {
    .item-list {
      margin-left: -10px;
      margin-right: -10px;
    }

    .item {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .products {
    .item {
      &.alt {
        .item-inner {
          display: block;

          .img {
            flex: 0 0 100%;
            max-width: 100%;
            border: 2px solid #ddddde;
            border-bottom: none;
          }

          .text {
            @include rem(padding, 25px);
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }
    }
  }

  .lab {
    .img {
      .inner {
        justify-content: flex-start;
      }

      .frame {
        max-width: 100px;
      }
    }
  }

  .mobile-nav {
    form.form-search {
      display: block;
      max-width: 540px;
      margin-left: auto;
      margin-right: auto;
      padding: 0 15px;
      @include rem(margin-bottom, 20px);
    }

    ul {
      li {
        padding: 0 15px;

        a {
          max-width: 510px;
          display: block;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }

  .product-filter {
    .col {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  .text-desc {
    @include rem(margin-bottom, 40px);

    &.low {
      @include rem(margin-bottom, 30px);
    }
  }

  .product-filter {
    .box {
      @include rem(padding, 10px 25px);
    }

    .filter-bar {
      .box {
        flex-wrap: wrap;

        h3 {
          @include rem(margin, 10px 20px 10px 0);
        }
      }

      .filter-l {
        flex-wrap: wrap;
      }

      .filter-trigger {
        @include rem(margin, 10px 0);
      }
    }

    .filter-content {
      .foot {
        flex-wrap: wrap;
      }

      .range-slider-wrapper {
        @include rem(padding, 0 10px);
        flex: 0 0 100%;
        max-width: 100%;
        @include rem(margin-bottom, 60px);
      }
    }
  }

  .contact-us {
    .btn-set {
      .inner {
        justify-content: flex-start;
        @include rem(padding-top, 30px);
      }
    }
  }

  form.form-search.active {
    .cell {
      max-width: 130px;
    }
  }

  // Temp logo in teaser
  .teaser .teaser-logo-float {
    position: static;
    float: right;
    margin-left: 40px;
    width: 80px;
    height: 80px;
    margin-top: 0;
  }
}

/*========================= Viewport width 575px and lower =========================*/

@media screen and (max-width: 575px) {
  html {
    font-size: 13px;
  }

  .header {
    /*
    .secondary-nav {
      height: auto;
    }

    &.search-opened {
      .h_1 {
        @include rem(margin-bottom, 90px);
      }

      ul {
        li {
          &.li-search {
            display: flex;
            animation: rotateMenu 400ms ease-in-out forwards;
            transform-origin: top center;
          }
        }
      }

      .dropdown-search {
        display: none;

        &.active {
          display: block;
        }
      }
    }
    */
  }

  .dropdown-search {
    display: none;

    &.active {
      display: none;
    }
  }

  .block {
    @include rem(padding, 40px 0);

    &.alpha {
      padding-top: 0;
    }

    &.omega {
      padding-bottom: 0;
    }
  }

  .posts {
    .item {
      .desc {
        @include rem(margin, 0 0 10px 0);
      }
    }
  }

  .secondary-nav {
    height: auto;

    ul {
      li {
        .txt {
          display: none;
        }

        &.li-search-trig {
          display: block;
        }
        /*
        &.li-search {
          display: none;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          @include rem(top, 100px);
          @include rem(height, 90px);
          //display: flex;
          align-items: center;
          padding-left: 15px;
          padding-right: 15px;
          background: #fff;
          overflow: hidden;

          &:after {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: -100%;
            width: 300%;
            height: 100%;
            background: #fff;
          }
        }
        */
      }
    }
  }

  .product-slider {
    .slider {
      @include rem(margin, 0 -5px);
    }

    .item {
      @include rem(padding, 0 5px);
    }
  }

  .slider-nav {
    ol {
      @include rem(margin, 0 -5px);

      li {
        @include rem(padding, 0 5px);
      }
    }
  }

  .cta {
    h2 {
      max-width: 320px
    }

    .phone {
      @include rem(font-size, 38px);
    }
  }

  .contact {
    .contact-list {
      ul {
        li {
          @include rem(font-size, 28px);
        }
      }
    }
  }

  .category-filter {
    flex-direction: column;
    .category-filter-item {
      display: block;
    }
  }

  .references {
    .item {
      .text {
        //@include rem(padding, 15px);
      }
    }
  }

  .categories {
    .item {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }

  .footer {
    .f_1 {
      @include rem(padding, 40px 0);
    }
  }

  .contact-us {
    .box {
      @include rem(padding, 30px);
    }
  }

  .gallery {
    .item-list {
      margin-left: -5px;
      margin-right: -5px;
    }

    .item {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .products {
    .item-list {
      margin-left: -5px;
      margin-right: -5px;
    }

    .item {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .product-detail {
    .text {
      .price {
        .more-info {
          font-size: 12px;
        }
      }
    }
  }

  .product-nav {
    ul {
      li {
        a {
          @include rem(font-size, 20px);
        }
      }

      ul {
        li {
          a {
            @include rem(font-size, 18px);
          }
        }
      }
    }
  }

  .icon-list {
    .item {
      .img {
        @include rem(width, 160px);
        @include rem(height, 160px);
      }
    }
  }

  form.form-search {
    width: 100%;
       
    .cell {
      max-width: 100% !important;
      width: 100% !important;
    }

    input.text {
      border: 2px solid #ddddde;
    }
  }
}