.fancynavbar-collapse {
	max-width: 690px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	font-weight: 400;
	font-size: 17px;
	display: block;
	width: 100%;
	padding: 14px 0;
	border: none;
	position: relative;
	$breakpoint-mobile: 767.98px;
	@media (max-width: $breakpoint-mobile) {
		max-width: 510px;
	}
}

.top {
	margin: 0.55556rem 0;
}
 
li {
	.fa {
		margin-right: 5px;
	}
}

.underline {
	&:hover {
		text-decoration: underline !important;
	}
}

.fancynav-item {
	padding: 14px 0;
	width: 100%;
	max-width: 140px;
	&:last-child {
		max-width: 160px;
	}
	$breakpoint-mobile: 520.98px;
	@media (max-width: $breakpoint-mobile) {
		padding: 14px 15px;
		max-width: 170px;
		&:last-child {
			max-width: 190px;
		}
	}
	.fancynav-link {
		justify-content: space-between;
	}
}

.fancynav-link {
	color: $white;
	text-decoration: none;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	width: 100%;
}

.white {
	&:hover{
		background-color: #fff;
	}
	&:hover > .fancynav-link {
		color: $black;
	}
}
	
.fancy-socials {
	$breakpoint-mobile: 530.98px;
	@media (max-width: $breakpoint-mobile) {
		padding: 14px 15px;
	}
	.row {
		padding: 8px;
		div {
			display: inline-block;
			border: 1px solid rgba(255, 255, 255, 0.3);
			border-radius: 2px;
			padding: 0.88889rem;
			margin: 0.9rem;
			width: 100%;
			max-width: 133px;
			transition: background-color 0.5s;
			$breakpoint-mobile: 530.98px;
			@media (max-width: $breakpoint-mobile) {
				margin: 0.2rem;
			}
			$breakpoint-mobile-smaller: 289.98px;
			@media (max-width: $breakpoint-mobile-smaller) {
				margin: 0.2rem !important;
			}
			&:first-child {
				margin: 0.9rem 0.9rem 0.9rem 0;
				$breakpoint-mobile: 530.98px;
				@media (max-width: $breakpoint-mobile) {
					margin: 0.2rem 0.2rem 0.2rem 0;
				}
			}
		}
	}
}