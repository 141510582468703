/************************************************************************************
RESET
*************************************************************************************/

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, figure, footer, header, hgroup, menu, nav, section, menu,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}

ol, ul { list-style: none; }

blockquote, q { quotes: none; }
blockquote:before, blockquote:after,
q:before, q:after { content: ''; content: none; }

:focus { outline: 0; }

table { border-collapse: collapse; border-spacing: 0; }

article, aside, figure, footer, header, hgroup, nav, section, img { display: block; }

input[type="radio"] { vertical-align: text-bottom; }
input[type="checkbox"] { vertical-align: bottom; *vertical-align: baseline; }

label, input[type=button], input[type=submit], button { cursor: pointer; overflow: visible; }

em, i { font-weight: inherit; }

/************************************************************************************
GENERAL
*************************************************************************************/

body {
	font-family: Barlow,sans-serif;
	@include rem(font-size, 18px);
	line-height: 1.6em;
	font-weight: $font-regular;
	color: #373737;
	background: #fff;
	margin: 0;
	padding: 0;
	min-height: 100%;
}

.header, .content, .footer {
	//max-width: 1920px;
	//margin: 0 auto;
}

.img-full {
	margin: 0 auto;
	max-width: 1920px;
}

/* Box sizing */

*, *:after, *:before {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}

/* Anchors */

a {
	color: #0077bb;
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
}

/* Heading */

h1, .h1 {
	font-family: Barlow,sans-serif;
	//@include rem(font-size, 63px);
	@include rem(font-size, 50px);
	line-height: 1.0em;
	font-weight: $font-semibold;
	color: $blue;
	@include rem(margin, 0 0 30px 0);

	&.light {
		font-weight: $font-light;

		.hl {
			font-weight: $font-semibold;
			display: block;
		}
	}
}

h2, .h2 {
	font-family: Barlow,sans-serif;
	@include rem(font-size, 42px);
	line-height: 1.1em;
	font-weight: $font-semibold;
	color: $blue;
	@include rem(margin, 0 0 10px 0);

	&.light {
		font-weight: $font-light;

		.hl {
			font-weight: $font-semibold;
			display: block;
		}
	}

	&.big {
		@include rem(font-size, 63px);
	}

	&.regular {
		font-weight: $font-regular;
	}

	&.black {
		color: #000;
	}
}

h3, .h3 {
	font-family: Barlow,sans-serif;
	@include rem(font-size, 32px);
	line-height: 1.3em;
	font-weight: $font-regular;
	color: $blue;
	@include rem(margin, 0 0 20px 0);

	&.big {
		@include rem(font-size, 24px);
	}

	&.medium {
		@include rem(font-size, 22px);
	}

	&.small {
		@include rem(font-size, 18px);
	  font-weight: $font-regular;
	  color: #373737;
	}

	&.smaller {
		@include rem(font-size, 16px);
	  font-weight: $font-regular;
	  color: #373737;
	}

	.hl {
		font-weight: $font-semibold;
	}
}

h4, .h4 {
	font-family: Barlow,sans-serif;
	@include rem(font-size, 25px);
	line-height: 1.3em;
	font-weight: $font-semibold;
	color: #000;
	@include rem(margin, 0 0 10px 0);
}

h5, .h5 {
	font-family: Barlow,sans-serif;
	@include rem(font-size, 20px);
	line-height: 1.3em;
	font-weight: $font-semibold;
	color: #000;
	@include rem(margin, 0 0 10px 0);
}

h6, .h6 {
	font-family: Barlow,sans-serif;
	@include rem(font-size, 20px);
	line-height: 1.3em;
	font-weight: $font-semibold;
	color: #000;
	@include rem(margin, 0 0 10px 0);
}

.suphead {
	@include rem(font-size, 17px);
	color: #8995a6;
	text-transform: uppercase;
	letter-spacing: 0.02em;
}

/* Paragraphs */

p {
	@include rem(margin, 0 0 20px 0);
}

address, cite {
	font-style: normal;
}

/* Lists */

ul {
	list-style: none;
	@include rem(margin, 0 0 20px 0);
	padding: 0;

	li {
		@include rem(padding, 0 0 0 20px);
		@include rem(margin, 0 0 5px 0);
	}

	&.unstyled li {
		padding: 0;
		background: none;
	}
}

ol {
	list-style: none;
	@include rem(margin, 0 0 20px 0);
	padding: 0;
	counter-reset: counter;
	margin-left: 0;

	li {
		@include rem(margin, 0 0 10px 0);
		padding: 0 0 0 20px;
		counter-increment: counter;
		position: relative;

		&:before {
			color: #2285c8;
			content: counter(counter) ". ";
			position: absolute;
			left: 0;
			top: 0;
		}
	}
}

dl {
	@include rem(margin, 0 0 20px 0);
}

dt {
	font-weight: bold;
}

/* Various */

sup, sub {
	height: 0;
	line-height: 1;
	vertical-align: baseline;
	_vertical-align: bottom;
	position: relative;
}

sup {
	bottom: 1ex;
}

sub {
	top: .5ex;
}

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;

	&.divider {
		margin: 0;
		border-top: 2px solid #f3f3f8;
	}
}

img {
	max-width: 100%;
	height: auto;
}

.bold, b, strong {
	font-weight: $font-semibold;
}

.italic, i, em {
	font-style: italic;
}

.nowrap, .nobr {
	white-space: nowrap !important;
}

/************************************************************************************
CONTENT
*************************************************************************************/

.content {
	padding: 0;
	overflow: hidden;
}

/* Block */

.block {
	@include rem(padding, 53px 0);
	position: relative;

	&.narrow {
		@include rem(padding, 40px 0);
	}
}

.block, .post-block {
	&.alt {
		background: #f5f5f5;
	}

	&.alt-2 {
		background: #162d5c;
	}

	&.alpha {
		padding-top: 0;
	}

	&.omega {
		padding-bottom: 0;
	}

	&.shadow-b {
		&:after {
			content: '';
			background: #f5f5f5;
			@include rem(height, 240px);
			width: 100%;
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			z-index: 1;
			pointer-events: none;
		}

		& > * {
			position: relative;
			z-index: 2;
		}
	}

	&.block-brands {
		background: #162e5d url('../img/block-brands.jpg') center 0 no-repeat;
	}

	&.block-tr {
		background: #f5f5f5 url('../img/block-tr.jpg') center bottom no-repeat;
	}

	&.block-lab {
		background: #f5f5f5 url('../img/block-lab.jpg') right center no-repeat;
	}
}

.spacer {
	@include rem(height, 55px);

	&.huge {
		@include rem(height, 105px);
	}

	&.big {
		@include rem(height, 65px);
	}

	&.medium {
		@include rem(height, 40px);
	}

	&.small {
		@include rem(height, 25px);
	}
}

.block-btn {
	@include rem(font-size, 16px);
	font-weight: $font-semibold;
	display: block;
	text-align: center;
	@include rem(padding, 10px 0);
	color: #000;
	border-top: 2px solid #ddddde;
	border-bottom: 2px solid #ddddde;
	@include transition(all, .3);

	.txt {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;

		.icon {
			@include rem(margin-left, 10px);
		  width: 12px;
		  height: 12px;
		  flex: 0 0 12px;
		  display: block;
		  color: #000;
		  //background: #000;
		}
	}
}

.anchor {
	position: absolute;
	left: 0;
	@include rem(top, 0);
}

.invert {
	color: #fff;

	h2 {
		color: #fff;

		.hl {
			color: $yellow;
		}
	}

	h3 {
		color: $yellow;
	}

	.entry {
		ul {
			li {
				&:before {
					background: $yellow;
				}
			}
		}
	}
}

/* Various */

.section-head {
	@include rem(margin-bottom, 40px);
	text-align: center;

	h1, h2, h3 {
		margin-bottom: 0;
	}
}

.intro {
	max-width: 500px;
	@include rem(margin, 0 auto 40px auto);
	text-align: center;

	&.wide {
		@include rem(max-width, 750px);
	}
}

.narrow-col {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

/* Pager */

.pager {
	@include rem(margin, 30px 0);
	text-align: center;
	position: relative;
	display: flex;
	justify-content: space-between;

	ul {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	li {
		padding: 0;
		background: none;

		a {
			display: flex;
			align-items: center;
			justify-content: space-between;
			@include rem(width, 36px);
			@include rem(height, 36px);
			background: #666;
			text-align: center;

			&.active {
				background: #000;
				color: #fff;
				text-decoration: none;
			}
		}
	}

	.prev {
		@include rem(width, 36px);
		@include rem(height, 36px);
		background: #000;
	}

	.next {
		@include rem(width, 36px);
		@include rem(height, 36px);
		background: #000;
	}

	.counter {
		display: none;
	}
}

/* Gmap */

.gmap-wrap {
	position: relative;

	.gmap {
		height: 600px;
		background: #e3e3e3;
	}

	img {
		max-width: inherit !important;
	}
}

.gmap {
  height: 500px;
}

.popup {
	min-width: 270px;

	.img {
		img {
			display: block;
			width: 100%;
		}
	}

	.text {
		@include rem(font-size, 16px);
		line-height: 1.5em;
		padding: 25px;

		ul {
			li {
				padding: 0;

				a {
					color: inherit;
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}

.gm-style {
  .gm-style-iw-c {
    max-width: 300px !important;
    max-height: inherit !important;
    padding: 0 !important;
    background: #fff;
    border-radius: 0;
    overflow: visible !important;
  }

  .gm-style-iw-t::after {
    background: #fff;
    box-shadow: none;
  }

  .gm-style-iw-d {
    max-width: inherit !important;
    overflow: visible !important;
    max-height: inherit !important;
  }

  .gm-ui-hover-effect {
    top: -30px !important;
    right: -5px !important;

    img {
      padding: 0 !important;
      margin: 0 !important;
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.gm-style .gm-ui-hover-effect { display: none !important; }

/*================================= Various Content =================================*/

.last {
	margin-bottom: 0 !important;
}

.fixer-start {
	position: relative;
}

.fixer {
	position: absolute;

	&.fixed {
		position: fixed;
		top: 0;
	}
}

/* Buttons */

.btn,
.sendbutton {
	@include rem(font-size, 16px);
	font-family: Barlow,sans-serif;
	line-height: 1.3em;
	font-weight: $font-semibold;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	background: $yellow;
	color: #000 !important;
	@include rem(padding, 0 20px);
	@include rem(height, 45px);
	text-decoration: none;
	position: relative;
	border: 2px solid $yellow;
	@include border-radius(7px);
	@include transition(all, .5);
	cursor: pointer;

	&:hover {
		background-color: $blue;
		border: 2px solid $blue;
		color: #fff;

		.icon-arr-r {
			left: 5px;
			@include transition(all, .3);
		}
	}

	&.line {
		background: none;
		color: #000;

		&:hover {
			background: $yellow;
			border-color: $yellow;
			color: #000;
		}
	}

	&.alt {
		background: #fff;
		border-color: #fff;

		&:hover {
			background: $yellow;
			border-color: $yellow;
			color: #000;
		}
	}

	&.big {
		@include rem(font-size, 19px);
		@include rem(height, 55px);
		@include rem(padding, 0 30px);
	}

	.icon {
		width: 11px;
		height: 11px;
		flex: 0 0 11px;
		@include rem(margin-left, 20px);
		position: relative;
		left: 0;
	}
}

.btn-yellow {
	@include rem(font-size, 16px);
	font-family: Barlow,sans-serif;
	line-height: 1.3em;
	font-weight: $font-semibold;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	background: $blue;
	color: #fff !important;
	@include rem(padding, 0 20px);
	@include rem(height, 45px);
	text-decoration: none;
	position: relative;
	border: 2px solid $blue;
	@include border-radius(7px);
	@include transition(all, .5);
	cursor: pointer;

	.icon-cart {
		color: #fff !important;
	}

	&:hover {
		background-color: $yellow;
		border: 2px solid $yellow;
		color: $blue !important;

		.icon-cart {
			left: 0 !important;
			color: $blue !important;
			@include transition(all, .3);
		}
	}
}

.btn-blue {

	.icon-cart {
		margin-left: 0;
		color: #000 !important;
	}

	&:hover {
		color: $yellow !important;

		.icon-cart {
			left: 0 !important;
			color: $yellow !important;
			@include transition(all, .3);
		}
	}
}

/* SVG icons */

.icon {
	width: 20px;
	height: 20px;
	flex: 0 0 20px;
	display: inline-block;
	vertical-align: top;
	position: relative;
	fill: currentColor;
}

/* Cookie Notice */

.cookie-notice {
	font-size: 14px;
	position: fixed;
	z-index: 10000;
	bottom: 0;
	width: 100%;
	padding: 10px 15px;
	background: rgba(0,0,0,0.90);
	color: #CCC;
	text-align: center;
}

.cookie-button {
	display: inline-block;
	padding: 2px 10px;
	margin-left: 25px;
	background: #02ad65;
	color: #fff;
	text-align: center;

	&:hover {
		background: #90ca16;
		color: #fff;
	}
}

.cookie-test {
	position: fixed;
	z-index: 10000;
	bottom: 0;
	width: 150px;
	padding: 25px 15px;
	background: rgba(50,50,50,0.95);
	color: #CCC;
	text-align: center
}

/* FY Apple! */

textarea,
input.text,
input[type="text"],
input[type="button"],
input[type="submit"],
.btn {
	-webkit-appearance: none;
}

/************************************************************************************
ENTRY
*************************************************************************************/

.entry {
	@include rem(margin, 0 0 40px 0);

	h2, h3 {
		margin-top: 30px;
	}

	h2:first-child, h3:first-child {
		margin-top: 0;
	}

	ul li {
		position: relative;

		&:before {
			content: '';
			position: absolute;
			left: 0;
			@include rem(top, 12px);
			width: 5px;
			height: 5px;
			display: block;
			background: $blue;
			border-radius: 50%;
		}
	}

	p:last-child {
		margin-bottom: 0;
	}
}

/************************************************************************************
TABLES
*************************************************************************************/

.entry {
	table {
		width: 100%;
		@include rem(margin, 0 0 30px 0);

		th {
			@include rem(font-size, 13px);
			line-height: 1.2em;
			font-weight: $font-semibold;
			border: none;
			@include rem(padding, 15px 10px);
			background: $blue;
			color: #fff;
			text-align: left;
			text-transform: uppercase;
			vertical-align: middle;

			&:nth-child(1) {
				padding-left: 15px;
			}

			&:last-child {
				padding-right: 15px;
			}
		}

		td {
			@include rem(font-size, 14px);
			border: none;
			@include rem(padding, 10px 10px);
			text-align: left;

			&:nth-child(1) {
				padding-left: 15px;
			}

			&:last-child {
				padding-right: 15px;
			}
		}

		tr:nth-child(even) {
			background: #f4f4f4;
		}
	}
}

/************************************************************************************
FORMS
*************************************************************************************/

form.std {
	legend {
		@include rem(font-size,18px);
		line-height: 1.3em;
		font-weight: 600;
		color: $blue;
		@include rem(margin, 0 0 20px 0);
	}

	label {
		@include rem(font-size,17px);
		line-height: 1.3em;
		color: #4c4c4c;
		@include rem(margin, 0 0 10px 0);
		display: block;
	}

	input.text {
		font-family: Barlow,sans-serif;
		@include rem(font-size, 18px);
		line-height: 1.3em;
		color: #000;
		border: 2px solid #ddddde;
		@include rem(padding, 5px 15px);
		@include rem(height, 50px);
		@include rem(border-radius, 6px);
		width: 100%;

		&.error {
			background: #fcf4f4;
			border-color: #be1e19;
		}

		&.req {
			background-image: url('../img/req.svg');
			background-position: right center;
			background-repeat: no-repeat;
		}
	}

	textarea {
		font-family: Barlow,sans-serif;
		@include rem(font-size, 18px);
		font-family: Barlow,sans-serif;
		line-height: 1.3em;
		color: #000;
		resize: vertical;
		border: 2px solid #ddddde;
		@include rem(padding, 15px 15px);
		width: 100%;
		min-width: 100%;
		max-width: 100%;
		height: 150px;
		min-height: 150px;
		max-height: 300px;
		@include rem(border-radius, 6px);
		display: block;

		&.req {
			background-image: url('../img/req.svg');
			background-position: right center;
			background-repeat: no-repeat;
		}
	}

	select {
		font-family: Barlow,sans-serif;
		@include rem(font-size, 18px);
		line-height: 1.3em;
		font-weight: 600;
		color: #000;
		width: 100%;
		@include rem(padding, 2px 10px);
	}

	option {
		font-family: Barlow,sans-serif;
		@include rem(font-size, 18px);
		line-height: 1.3em;
		@include rem(padding, 2px 10px);
	}

	input.text[required="required"] {
		//background-image: url('../img/req.png');
		//background-position: right center;
		//background-repeat: no-repeat;
	}

	.cell {
		@include rem(margin-bottom, 20px);
		position: relative;
		text-align: left;
	}

	.toggle {
		position: relative;
		@include rem(padding, 0 0 0 25px);

		label {
			display: inline-block;
			vertical-align: top;
			margin: 0;
		}

		input {
			margin: 0;
			position: absolute;
			left: 0;
			@include rem(top, 3px);
		}
	}

	.radio, .checker {
		position: absolute;
		left: 0;
		top: 0;
	}
}

form.form-contact {
	.cell {
		@include rem(margin-bottom, 10px);
	}

	.btn {
		&.full {
			width: 100%;
		}
	}

	.btn-set {
		display: flex;
		justify-content: center;
	}
}

form.form-search {
	.cell {
    position: relative;
    max-width: 115px;
    @include transition(all, .3);

    .icon-search {
      @include rem(width, 26px);
      @include rem(height, 26px);
      @include rem(flex, 0 0 26px);
      position: absolute;
      left: 10px;
      top: 50%;
      @include rem(margin-top, -13px);
      //background: #e3e3e3;
    }
  }

  &.active {
  	.cell {
  		max-width: 250px;
  	}
  }

	input.text {
	width: 100%;
    padding-left: 40px;
    padding-right: 0;
    border-color: transparent;
    @include transition(all, .3);
  }

  &.active {
    input.text {
      border-color: #ddddde;
      padding-right: 15px;
    }
  }

  .icon {
  	//background: #000;
  }
}

form.form-sorting {
	@include rem(margin-bottom, 10px);

	.cell-set {
		display: flex;
		justify-content: flex-end;
	}

	.cell {
		display: flex;
		align-items: center;
		display: inline-flex;

		label {
			@include rem(font-size, 18px);
			text-transform: uppercase;
			font-weight: 600;
			margin: 0;
			white-space: nowrap;
		}

		select {
			@include rem(font-size, 18px);
			border: none;
			display: inline-block;
			text-transform: uppercase;
		}
	}
}

form.form-filter {
	label {
		font-size: 14px;
		color: #373737;
	}

	.cell {
		@include rem(margin-bottom, 10px);
	}
}

#form-ajax-content {
	position: relative;

	&.loading:after {
		content: '';
		position: absolute;
		z-index: 90;
		left: 50%;
		top: 50%;
		width: 70px;
		height: 70px;
		background: url('../img/spinner.svg') center center no-repeat;
		background-size: cover !important;
		@include translate(-50%,-50%);
	}

	& > .inner {
		opacity: 1;
		@include transition(all,.3);
	}

	&.loading > .inner {
		opacity: 0.4;
	}
}

.ajax-loader {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,.5);
	z-index: 200;

	&:after {
		content: '';
		position: fixed;
		z-index: 90;
		left: 50%;
		top: 50%;
		width: 60px;
		height: 60px;
		border-radius: 5px;
		box-shadow: 0 0 5px 0 rgba(0,0,0,.2);
		background: #fff url('../img/spinner.svg') center center no-repeat;
		background-size: cover !important;
		@include translate(-50%,-50%);
	}
}

/*================================= Contact form 7 =================================*/

/* Mesages & errors */

body {
	div.wpcf7-response-output {
		@include rem(font-size, 16px);
		line-height: 1.4em;
		padding: 0;
		background: #fae7ea;
		border: 1px solid #f1c7d2;
		color: #e5547a;
		text-align: left;
		@include rem(padding, 15px 20px);
		@include rem(margin, 0 0 30px 0);

		&.wpcf7-mail-sent-ok {
			background: #ebf5bc;
			border: 1px solid #c7d198;
			color: #98b028;
		}
	}

	span.wpcf7-not-valid-tip {
		font-size: 13px;
	}

	span.wpcf7-list-item {
		margin: 0;
	}

	.wpcf7-form-control-wrap {
		position: static;
	}

	div.wpcf7 {
	 .ajax-loader {
			background-image: url('../img/spinner.svg');
			background-size: cover !important;
			width: 40px;
			height: 40px;
		}
	}
}

/* Mesages & errors */

.flash-message {
	@include rem(font-size, 16px);
	padding: 0;
	background: #ebf5bc;
	border: 1px solid #96d775;
	color: #46b450;
	@include rem(padding, 15px 20px);
	@include rem(margin, 0 0 30px 0);

	&.error {
		border: 1px solid #f1c7d2;
		background: #fae7ea;
		color: #e5547a;
	}
}

/************************************************************************************
TABS
*************************************************************************************/

.tabs {
	position: relative;

	.tab-nav {
		@include rem(margin-bottom, 30px);
		display: block;

		ul {
			@include rem(margin, 0 -10px);
			padding: 0;
			display: flex;
			flex-wrap: wrap;
		}

		li {
			list-style-type: none;
			@include rem(padding, 5px 10px);
			background: none;
			margin: 0;

			a {
				font-weight: $font-semibold;
				display: inline-flex;
				align-items: center;
				text-decoration: none;
				outline: 0;
				@include rem(padding, 0 30px);
				background: none;
				color: #7f7f7f;
				border: 2px solid $yellow;
				@include rem(height, 55px);
				@include rem(border-radius, 27px);
				@include transition(all, .3);

				&:hover {
					background: $yellow;
					color: #000;
				}

				&.selected {
					background: $yellow;
					color: #000;
				}
			}
		}

        &.tab-nav-reverse {
            li {
                a {
                    border-color: $blue;
                    color: $blue;

                    &:hover {
                        background: $blue;
                        color: $white;
                    }

                    &.selected {
                        background: $blue;
                        color: $white;
                    }
                }
            }
        }
	}

	.tab-content .tab {
		width: 100%;
		display: none;

		&.selected {
			position: static !important;
			display: block;
		}
	}
}

/************************************************************************************
QTIP
*************************************************************************************/

.qtip-default {
	padding: 8px 15px;
	background-color: #000;
	color: #000;
	@include border-radius(5px);
}

.qtip-content {
	@include rem(font-size, 16px);
	color: #fff;
}

/************************************************************************************
SIMPLE LIGHTBOX
*************************************************************************************/

body {
	.slbArrow {
		font-size: 15px;

		&.prev {
			&:before {
				border: none;
				border-right: 2px solid #fff;
				border-bottom: 2px solid #fff;
				@include rotate(135deg);
				width: 20px;
				height: 20px;
			}

			&:hover {
				opacity: 1;
			}
		}

		&.next {
			&:before {
				border: none;
				border-right: 2px solid #fff;
				border-bottom: 2px solid #fff;
				@include rotate(-45deg);
				width: 20px;
				height: 20px;
			}

			&:hover {
				opacity: 1;
			}
		}
	}

	.slbCloseBtn {
		@include rem(font-size, 30px);
		line-height: 0;
		font-weight: 100;
		font-family: Arial,sans-serif;
		border: none;
		color: #fff;
		@include rem(width, 40px);
		@include rem(height, 40px);
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0;
		right: 0;
		top: 0;
		position: fixed;
		background: $blue;

		&:hover {
			background: $yellow;
			color: #000;
			opacity: 1;
		}
	}

	.slbCaption {
		@include rem(font-size, 16px);
	}
}

/************************************************************************************
MODAL
*************************************************************************************/

.modal {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(0,0,0,.5);
  overflow: auto;
  align-items: center;
  justify-content: center;

  &.active {
    display: flex;
  }

  .modal-content {
  	@include rem(padding, 50px);

  	&.alt {
  		background: #f9fbfc;
  	}
  }

  .modal-box {
	  @include rem(font-size, 18px);
	  @include rem(max-width, 400px);
	  background-color: #fff;
	  line-height: 1.5em;
	  margin: auto;
	  position: relative;

	  .modal-close {
	    position: absolute;
	    right: 0;
	    top: 0;
	    text-decoration: none;
	    @include rem(width, 40px);
	    @include rem(height, 40px);
	    display: flex;
	    justify-content: center;
	    align-items: center;
	    background: #000;
	    color: #fff;

	    &:after {
	    	content: '×';
	    	@include rem(font-size, 30px);
	    	position: absolute;
	    	left: 0;
	    	top: 0;
	    	width: 100%;
	    	height: 100%;
	    	display: flex;
	    	justify-content: center;
	    	align-items: center;
	    	color: #fff;
	    }

	    .icon {
	    	@include rem(width, 18px);
	    	@include rem(height, 18px);
	    	transform: rotate(0deg);
	    	@include transition(all, .3);
	    }

	    &:hover {
	    	background: #fff;

	    	&:after {
	    		color: #000;
	    	}

	    	.icon {
	    		transform: rotate(90deg);
	    	}
	    }
	  }

	  &.modal-order {
			width: 100%;
			@include rem(max-width, 580px);
			background: #e5e5e5;
		}
	}

	.modal-box-wrap {
	  width: 100%;
	  //max-width: 500px;
	  @include rem(padding, 50px 15px 15px);
	  margin: auto;
	  overflow: hidden;
	  position: relative;
	  animation-name: animateTop;
	  animation-duration: .4s;
	}
}

@keyframes animateTop {
  from {
    top: -300px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

/************************************************************************************
SUDO SLIDERS
*************************************************************************************/

.slider {
	overflow: hidden;
	background: none;

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		position: relative;
		display: block;
	}

	li.panel {
		margin: 0;
		padding: 0;
		list-style: none;
		position: relative;
		display: block;
		overflow: hidden;
		background: none;
	}
}

.slider-nav {
	display: block;
	position: static;
	@include rem(margin-bottom, 50px);

	ol {
		@include rem(margin, 0 -10px);
		display: flex;
		justify-content: center;
		position: relative;
		@include rem(top, 50px);
		height: 0;
	}

	li {
		margin: 0;
		@include rem(padding, 0 10px);
		background: none;
		display: block;
		font-size: 0;

		a {
			@include rem(width, 9px);
			@include rem(height, 9px);
			display: block;
			background: #c7c2be;
			text-indent: -80000px;
			@include border-radius(50%);

			&:hover {
				background: #666666;
			}
		}

		&.current a {
			background: #666666;
		}
	}

	li:before {
		content: '';
	}

	.prev {
		position: absolute;
		@include rem(left,-38px);
		top: 50%;
		@include translate(0,-50%);
		display: flex;
		align-items: center;
		justify-content: center;
		@include rem(width,76px);
		@include rem(height,76px);
		@include border-radius(50%);
		background: $yellow;
		@include transition(all, .3);

		&:hover {
			background: $blue;
			color: #fff;
		}

		.icon {
			@include rem(width, 21px);
			@include rem(height, 21px);
			color: #000;
			//background: #000;
		}
	}

	.next {
		position: absolute;
		@include rem(right,-38px);
		top: 50%;
		@include translate(0,-50%);
		display: flex;
		align-items: center;
		justify-content: center;
		@include rem(width,76px);
		@include rem(height,76px);
		@include border-radius(50%);
		background: $yellow;
		@include transition(all, .3);

		&:hover {
			background: $blue;
			color: #fff;
		}

		.icon {
			@include rem(width, 21px);
			@include rem(height, 21px);
			color: #000;
			//background: #000;
		}
	}

	/*
	.prev[style*="opacity: 0"], .next[style*="opacity: 0"] {
		visibility: visible !important;
		opacity: 1 !important;
		background-color: #eaeaea;
		pointer-events: none;

		.icon {
			color: #868686;
		}
	}
	*/
}

/* Item slider */

.item-slider {
	position: relative;

	&.hide-nav {
		.slider-nav {
			display: none;
		}
	}
}

/* Product slider */

.product-slider {
	position: relative;

	.slider {
		@include rem(margin, 0 -15px);
	}

	li.panel {
		margin: 0;
		display: flex !important;
		height: 100%;
	}

	.item {
		@include rem(padding, 0 15px);
	}
}

/* Partner slider */

.partner-slider {
	@include rem(margin-bottom, 40px);

	.slider {
		margin: 0 -15px;
	}

	.item {
		padding: 0 15px;
		display: flex;
		justify-content: center;
	}
}

/* Image slider */

.img-slider {
	overflow: hidden;

	.slider-nav {
		margin: 0;

		.prev {
			@include rem(width, 38px);
			height: 100%;
			border-radius: 0;
			left: 0;
			background: none;
			display: flex;
			justify-content: center;

			&:after {
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 15px 15px 15px 0;
				border-color: transparent $yellow transparent transparent;
				position: absolute;
				left: 50%;
				top: 50%;
				@include translate(-50%,-50%);
			}
		}

		.next {
			@include rem(width, 38px);
			height: 100%;
			border-radius: 0;
			right: 0;
			background: none;

			&:after {
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 15px 0 15px 15px;
				border-color: transparent transparent transparent $yellow;
				position: absolute;
				left: 50%;
				top: 50%;
				@include translate(-50%,-50%);
			}
		}
	}
}

/************************************************************************************
CLEARFIX
*************************************************************************************/

/* http://www.yuiblog.com/blog/2010/09/27/clearfix-reloaded-overflowhidden-demystified */

.clearfix:before,
.clearfix:after {
	content: '\0020';
	display: block;
	overflow: hidden;
	visibility: hidden;
	width: 0;
	height: 0;
}

.clearfix:after {
	clear: both;
}

.clearfix {
	zoom: 1;
}

/* alert */
.alert {
    padding: .875rem .875rem .875rem 3rem;
    background-repeat: no-repeat;
    background-position: left 10px center;
    border-radius: 4px;
    border-width: 0;
    background-color: transparent;
    text-align: left;
    font-weight: 500;
    line-height: 1.3em;
    font-size: 1.125rem;

    * {
        margin-bottom: 0;
    }

    h6 {
        margin-bottom: 2px;
    }

    &.alert-warning {
        color: #ad6b08;
        background-color: #fcf7dc;
        background-image: url('../img/icon-warning.svg') !important;
        background-size: 1.25rem 1.25rem;
    }
}
