.has-topbar {
  main.main {
    padding-top: $topnavbar-height;
    $breakpoint-tablet: 991.98px;
    @media (max-width: $breakpoint-tablet) {
      padding-top: $navbar-height-mobile;
      padding-top: 0;
    }
  }
}

.topbar.com {
  background: $blue;
  color: $white;
  font-size: 15px;
  $breakpoint-tablet: 991.98px;
  @media (max-width: $breakpoint-tablet) {
    display: none;
  }
  .container {
    height: $topnavbar-height;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  ul {
    list-style-type: none;
    padding-left: 0;
    li {
      display: inline-block;
      &:first-child {
        padding: 0;
      }
    }
    li + li {
      padding-left: 20px;
      margin-left: 10px;
      position: relative;
      &:before {
        content: ' ';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 1px;
        height: 0.5em;
        background: #fff8;
      }
    }
  }
  a {
    color: $white;
    text-decoration: none;
  }
}
