/************************************************************************************
LAYOUT
*************************************************************************************/
.category-filter {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .category-filter-item {
    position: relative;
    padding-left: 36px;
    font-size: 1rem;
    font-weight: 700;
    margin: 1rem;
    color: #373737;

    &:before {
      content: '';
      width: 26px;
      height: 26px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      border: 2px solid #fad611;
      border-radius: 7px;
    }

    &.active {
      &:before {
        background-image: url('../img/check.svg');
        background-repeat: no-repeat;
        background-position: 50% 55%;
        background-size: 70% auto;
      }
    }
  }
}

.map-contact {
  height: 500px;
  background: url(../img/map-contact.jpeg) no-repeat 50% 0;
  background-size: auto 100%;
  .marker {
    max-width: 270px;
    background: white;
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    &:after {
      background: linear-gradient(45deg,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 51%,rgba(255,255,255,0) 100%);
      content: "";
      height: 16px;
      left: 50%;
      position: absolute;
      bottom: -16px;
      margin-left: -8px;
      transform: translate(-50%,-50%) rotate(-45deg);
      width: 16px;
    }
  }
}

.text-desc {
  @include rem(margin-bottom, 45px);

  &.low {
    @include rem(margin-bottom, 40px);
  }

  &.mb-0 {
    margin-bottom: 0 !important;

    * {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.products {
  h3 {
    @include rem(margin-bottom, 10px);
    font-weight: 600;
    color: $blue;

    a {
      color: $blue;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .item {
    @include rem(margin-bottom, 30px);
    display: flex;

    .img {
      display: block;
      border: 2px solid #ddddde;
      border-bottom: none;
      @include rem(padding, 30px 15px);
      background: #fff;

      img {
        display: block;
        margin: 0 auto;
      }
    }

    .desc {
      flex-grow: 1;
    }

    .item-inner {
      text-decoration: none;
    }

    .text {
      border: 2px solid #ddddde;
      @include rem(padding, 25px);
      background: #fff;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }

    &.alt {
      .item-inner {
        display: flex;
        text-decoration: none;

        .img {
          flex: 0 0 50%;
          max-width: 50%;
          border: 2px solid #ddddde;
          border-right: none;
        }

        .text {
          flex: 0 0 50%;
          max-width: 50%;
          @include rem(padding-top, 60px);
        }
      }
    }

    .tags {
      @include rem(margin-bottom, 10px);
    }

    .price {
      line-height: 1.3em;
      font-weight: $font-semibold;
      color: $blue;
      @include rem(margin-bottom, 10px);

      .suffix {
        @include rem(font-size, 15px);
        font-weight: $font-regular;
        white-space: nowrap;
      }
    }

    &:hover {
      .item-inner {
        h2, h3, h4, h5, h6 {
          text-decoration: underline;
        }
      }
    }
  }

  .item-inner {
    display: block;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .foot {
    @include rem(padding-top, 15px);
    display: flex;
    justify-content: center;
  }
}

.systems {
  h2 {
    @include rem(margin-bottom, 30px);
  }

  .item {
    @include rem(font-size, 22px);
    color: #fff;
    @include rem(margin-bottom, 30px);
    display: flex;

    .img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      img {
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .text {
      position: relative;
      z-index: 10;
    }

    .desc {
      max-width: 350px;
    }

    h3 {
      color: #fff;
      @include rem(margin-bottom, 20px);

      &:hover {
        text-decoration: underline;
      }
    }

    &.c-1 {
      .item-inner {
        background: #274f95;
      }
    }

    &.c-2 {
      .item-inner {
        background: #1a3465;
      }
    }

    &.c-3 {
      .item-inner {
        background: #0e2550;
      }
    }

    &.c-4 {
      h3 {
        color: #000000;
      }

      .item-inner {
        background: #e6e6e6;
      }
    }
  }

  .item-inner {
    background: #274f95;
    @include rem(padding, 90px 40px);
    width: 100%;
    overflow: hidden;
    display: block;
    position: relative;
    text-decoration: none;
    color: #fff;

    &:after {
      content: '';
      background: rgba(0, 40, 110, 0.7);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .btn {
    &:hover {
      background: #fff;
      border-color: #fff;
      color: #000;
    }
  }
}

.posts {
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @include rem(margin, 0 0 60px 0);

    h2 {
      @include rem(margin, 10px 0);
    }

    .btn {
      @include rem(margin, 10px 0);
    }
  }

  .item {
    @include rem(margin, 0 0 33px 0);
    display: flex;
    flex-direction: column;

    .post-img {
      margin-bottom: 1.25rem;
    }

    h3 {
      font-weight: $font-semibold;

      a {
        color: #373737;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .date {
      font-weight: $font-semibold;
      margin-bottom: 0;
    }

    .desc {
      @include rem(margin, 0 0 45px 0);
      flex-grow: 1;
    }
  }
}

.post {
  .post-head {
    @include rem(margin-bottom, 50px);
  }
}

.tag-list {
  display: inline-flex;

  ul {
    display: flex;
    flex-wrap: wrap;
    @include rem(margin, 0 -2px);

    li {
      @include rem(padding,  2px);

      a {
        @include rem(font-size, 16px);
        font-weight: $font-semibold;
        background: #eaeaea;
        border-radius: 3px;
        color: #868686;
        text-decoration: none;
        @include rem(padding, 2px 8px);

        &:hover {
          background: $blue;
          color: #fff;
        }
      }
    }
  }
}

.cta {
  color: #fff;
  @include rem(padding, 37px 0);

  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  h2 {
    @include rem(margin, 5px 0);
  }

  .phone {
    @include rem(font-size, 42px);
    line-height: 1.3em;
    @include rem(margin, 5px 0);
    display: flex;
    align-items: center;
    white-space: nowrap;

    a {
      font-weight: $font-light;
      text-decoration: none;
      color: #fff;
      display: flex;
      align-items: center;
    }

    .icon {
      @include rem(margin-right, 20px);
      @include rem(width, 54px);
      @include rem(height, 54px);
      @include rem(flex, 0 0 54px);
      color: #fff;
    }
  }

  &.invert {
    .phone {
      a:hover {
        color: $yellow;
      }
    }
  }
}

.references {
  .item-list {
    margin: 0;
  }

  .item {
    padding: 0;

    h3 {
      color: #fff;

      &:hover {
        text-decoration: underline;
      }

      a {
        color: #fff;
      }
    }

    .black {
      color: $black;

      &:hover {
        text-decoration: underline;
      }
    }
    .text {
      position: relative;
      z-index: 20;
      display: flex;
      @include rem(padding, 50px);
    }

    .img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      img {
        @include transition(all, .3);
        @include scale(1);
        object-fit: cover !important;
        width: 100%;
        height: 100%;
      }

      &:after {
        content: '';
        background: rgba(0,40,110,.7);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }

    .cat {
      color: $yellow;
      display: flex;
      align-items: center;

      .icon {
        @include rem(width, 20px);
        @include rem(height, 20px);
        @include rem(margin-right, 10px);
        color: $yellow;
        //background: $yellow;
      }
    }

    .btn {
      //color: #fff;

      &:hover {
        //color: #000;
      }

      /*&:after {
        background-image: url('../img/arr-r-alt.svg')
      }*/
    }

    .btn-set {
      @include rem(flex, 0 0 120px);
      display: flex;
      justify-content: flex-end;
    }
  }

  .item-inner {
    display: flex;
    text-decoration: none;
    overflow: hidden;
    position: relative;
    @include rem(min-height, 380px);
    padding-bottom: 82%;
    height: 0;

    &:hover {
      .img {
        img {
          @include scale(1.05);
        }
      }
    }
  }

  .item-inner.pr-sd {
    padding-bottom: 60%;
  }
}

.partners {
  .head {
    font-weight: $font-semibold;
    color: #989898;
  }

  .item {
    .img {
      img {
        display: block;
        margin: 0 auto;
      }
    }
  }
}

.partners-list {
  h3 {
    @include rem(margin-bottom, 30px);
  }
}

.alphabet {
  h2 {
    @include rem(margin-bottom, 30px);
  }

  h3 {
    @include rem(margin-bottom, 30px);
  }

  .partners-slider {
    @include rem(margin-bottom, 30px);
  }

  .foot {
    display: flex;
    justify-content: center;
    @include rem(padding-top, 30px);
  }
}

.letter-list {
  @include rem(font-size, 42px);
  line-height: 1.3em;
  color: $blue;
  font-weight: 600;
  @include rem(margin-bottom, 40px);

  ul {
    display: flex;
    @include rem(margin, 0 -4px);
    flex-wrap: wrap;

    li {
      @include rem(padding, 0 4px);
      margin: 0;

      &.inactive {
        a {
          color: #ddd;
          cursor: default;
          text-decoration: none !important;
        }
      }

      a {
        text-decoration: none;
        color: $blue;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.comparing {
  overflow-x: auto;
  margin-left: -15px;
  margin-right: -15px;

  .wrap {
    //overflow: hidden;
  }

  .item-list {
    flex-wrap: nowrap;
    @include rem(padding-top, 14px);
    @include rem(padding-bottom, 14px);
    margin: 0;
  }

  .item-inner {
    border: 2px solid #ddddde;
    border-top: none;
    width: 100%;
    position: relative;
  }

  .item {
    display: flex;
    flex-shrink: 0;

    .text {
      @include rem(padding, 20px 24px);
    }

    .head {
      @include rem(height, 120px);
    }

    .params {
      ul {
        li {
          @include rem(padding, 5px 24px);

          &:nth-child(even) {
            background: #f4f4f4;
          }
        }
      }
    }

    .price {
      @include rem(font-size, 18px);
      line-height: 1.3em;
      color: $blue;
      font-weight: $font-semibold;
      @include rem(margin, 0 0 20px 0);

      .suffix {
        font-weight: $font-regular;
        white-space: nowrap;
      }
    }

    .close {
      position: absolute;
      right: 0;
      top: 0;
      @include rem(width, 28px);
      @include rem(height, 28px);
      @include rem(margin-top, -14px);
      @include rem(margin-right, -14px);
      display: flex;
      justify-content: center;
      align-items: center;
      background: $yellow;
      color: #000;
      border-radius: 50%;
      @include transition(all, .3);

      &:hover {
        background: $blue;
        color: #fff;
      }

      .icon {
        @include rem(width, 12px);
        @include rem(height, 12px);
      }
    }

    .btn {
      @include rem(padding-left, 15px);
      @include rem(padding-right, 15px);
    }

    h3 {
      color: $blue;
      font-weight: 600;
      margin-bottom: 0;
    }

    &.empty {
      min-height: 500px;

      .item-inner {
        border: none;
        background: #e5eaf2;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .plus {
        @include rem(width, 80px);
        @include rem(height, 80px);
        background: #fff;
        border-radius: 50%;
        margin: 0 auto 60px auto;
        display: block;
        color: $blue;
      }
    }

    &.item-labels {
      .item-inner {
        border: none;
      }

      .params {
        color: $blue;
      }
    }
  }
}

.box {
  background: #e5eaf2;
  @include rem(padding, 30px);
}

.contact-us {
  div.text {
    @include rem(font-size, 22px);
    display: flex;
    align-items: center;

    .desc {
      @include rem(margin, 30px 0);

      &:last-child {
        margin-bottom: 0;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    .inner {
      @include rem(max-width, 470px);
    }

    ul {
      max-width: 320px;
    }

    li {
      &.phone, &.mail {
        @include rem(font-size, 22px);
        padding: 0;
        @include rem(margin-bottom, 30px);

        a {
          color: $blue;
          background: #e5eaf2;
          display: inline-flex;
          @include rem(height, 55px);
          align-items: center;
          @include rem(padding, 0 35px);
          border-radius: 6px;
          text-decoration: none;
          width: 100%;
          @include transition(all, .3);

          &:hover {
            color: #fff;
            background: $blue;
          }

          .icon {
            @include rem(width, 30px);
            @include rem(height, 30px);
            //background: $blue;
            @include rem(margin-right, 10px);
          }
        }
      }
    }
  }

  .btn-set {
    align-items: center;
    display: flex;

    .inner {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }

  .box {
    @include rem(padding, 55px);
    border-radius: 6px;
  }
}

.categories {
  @include rem(margin, -10px 0);

  .item-list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    @include rem(margin, 0 -10px);
  }

  .item {
    text-align: center;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
    @include rem(margin, 10px 0);

    .img {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      @include rem(border-radius, 6px);
      border: 2px solid #ddddde;
      @include rem(width, 70px);
      @include rem(height, 70px);
      @include rem(margin-bottom, 10px);
      @include transition(all, .3);

      &:hover {
        border-color: $blue;
      }

      .icon {
        @include rem(width, 30px);
        @include rem(height, 30px);
      }
    }

    h3 {
      @include rem(font-size, 12.3px);
      color: #878787;

      &.break {
        overflow-wrap: break-word;
        word-wrap: break-word;
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        hyphens: auto;
      }

      a {
        color: #878787;
        text-decoration: none;
      }
    }

    &.active {
      .img {
        background: $blue;
        border-color: $blue;
        color: #fff;
      }

      h3 {
        color: $blue;

        a {
          color: $blue;
        }
      }
    }
  }

  .item-inner {
    max-width: 90px;
    margin: 0 auto;
  }
}

.category-list {
  counter-reset: counter;

  .item-list {
    @include rem(margin-bottom, -40px);
  }

  .item {
    @include rem(margin-bottom, 40px);
    display: flex;
    position: relative;

    .img {
      border: 2px solid #ddddde;
      @include rem(height, 270px);
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      img {
        display: block;
        margin: 0 auto;
        @include transition(all, .3);
        @include scale(1);
      }
    }

    .text {
      border: 2px solid #ddddde;
      border-top: none;
      @include rem(padding, 20px 20px 20px 20px);
      display: flex;
      align-items: center;
      width: 100%;
      flex-grow: 1;
      position: relative;
      @include transition(all, .3);
    }

    h3 {
      font-weight: 600;
      margin-bottom: 0;
      @include transition(all, .3);

      .icon {
        position: absolute;
        @include rem(right, 25px);
        top: 50%;
        @include rem(width, 25px);
        @include rem(height, 25px);
        @include rem(margin-top, -12.5px);
        //background: #e3e3e3;
        color: #1d1d1b;
        @include rotate(0deg);
        @include transition(all, .3);
      }
    }

    &.active {
      .text {
        background: $blue;
        border-color: $blue;
        color: #fff;

        h3 {
          color: #fff;

          .icon {
            @include rotate(180deg);
            color: #fff;
          }
        }

        &:before {
          color: #fff;
        }
      }
    }
  }

  .item-desc {
    display: none;
    @include rem(font-size, 20px);

    &.active {
      display: block;
    }

    .btn {
      width: 100%;
      border-radius: 0;
      @include rem(height, 56px);

      &:hover {
        color: #fff;
      }
    }

    .block-btn {
      border: 2px solid #ddddde;
      background: #f5f5f5;
      color: #000;
    }

    ul {
      &:last-child {
        margin-bottom: 0;
      }

      li {
        margin-bottom: 15px;
        @include rem(padding-left, 25px);
        position: relative;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          @include rem(top, 12px);
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: #274f95;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    a {
      color: #505050;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .item-main {
    counter-increment: counter;

    .text {
      &:before {
        content: none;
        //@include rem(font-size, 57px);
        //@include rem(font-size, 22px);
        //@include rem(margin-right, 10px);
        //line-height: 1em;
        //font-weight: 600;
        //color: $blue;
        //content:  "0" counter(counter);
        //position: absolute;
        //@include rem(left,  15px);
        //top: 50%;
        //@include translate(0,-50%);
      }
    }

    &.active {
      &:after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -40px;
        margin-left: -8px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 16px 15px 16px;
        border-color: transparent transparent #e5eaf2 transparent;
      }
    }
  }

  .item-inner {
    display: block;
    text-decoration: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    &:hover {
      .img {
        img {
          @include scale(1.05);
        }
      }

      h3 {
        text-decoration: underline;
      }
    }
  }
}

.category-fields {
  counter-reset: counter;

  .item-list {
    @include rem(margin-bottom, -30px);
    counter-reset: counter;
  }

  .item {
    @include rem(margin-bottom, 30px);
    display: flex;
    position: relative;
    counter-increment: counter;

    .img {
      border: 2px solid #ddddde;
      @include rem(height, 190px);
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      .icon {
        @include rem(width, 53px);
        @include rem(height, 53px);
        color: #aaaaaa;
      }
    }

    .text {
      border: 2px solid #ddddde;
      border-top: none;
      @include rem(padding, 20px 20px 20px 20px);
      display: flex;
      align-items: center;
      width: 100%;
      flex-grow: 1;
      position: relative;
      @include transition(all, .3);

      .num {
        //@include rem(font-size, 57px);
        //@include rem(font-size, 22px);
        //@include rem(margin-right, 10px);
        //line-height: 1em;
        //font-weight: 600;
        //color: $blue;
        //content:  "0" counter(counter);
        //position: absolute;
        //@include rem(left,  15px);
        //top: 50%;
        //@include translate(0,-50%);
        display: none;
      }
    }

    h3 {
      font-weight: 600;
      margin-bottom: 0;
      @include transition(all, .3);

      .icon {
        position: absolute;
        @include rem(right, 25px);
        top: 50%;
        @include rem(width, 25px);
        @include rem(height, 25px);
        @include rem(margin-top, -12.5px);
        //background: #e3e3e3;
        color: #1d1d1b;
        @include rotate(0deg);
        @include transition(all, .3);
      }
    }
  }

  .item-inner {
    display: block;
    text-decoration: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    &:hover {
      .img {
        img {
          @include scale(1.05);
        }
      }

      .icon {
        color: $blue;
      }

      h3 {
        text-decoration: underline;
      }
    }
  }
}

.gallery {
  .item {
    @include rem(margin-bottom, 20px);
  }
}

.img-full {
  img {
    width: 100%;
    display: block;
    margin: 0 auto;
  }

  .desc {
    font-weight: 600;
    @include rem(padding-top, 20px);
  }
}

.lab {
  .img {
    .inner {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.navigator {
  .head {
    font-weight: $font-semibold;
    color: $blue;
  }

  ol {
    li {
      @include rem(font-size, 32px);
      line-height: 1.3em;
      @include rem(margin-bottom, 10px);
      @include rem(padding-left, 30px);

      a {
        color: #505050;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      &:before {
        color: #505050;
      }
    }
  }
}

.tags {
  ul {
    margin: 0 -3px;
    display: flex;
    flex-wrap: wrap;

    li {
      padding: 0 3px;
      @include rem(margin, 2px 0);

      a {
        @include rem(font-size, 15px);
        line-height: 1.3em;
        font-weight: $font-semibold;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        @include rem(padding, 3px 10px);
        border-radius: 3px;
        @include transition(all, .3);
      }

      &.vyprodej {
        a {
          background: #ddf1f1;
          color: #14a0a1;

          &:hover {
            background: #14a0a1;
            color: #fff;
          }
        }
      }

      &.doprodej {
        a {
          background: #f5ede1;
          color: #b17311;

          &:hover {
            background: #b17311;
            color: #fff;
          }
        }
      }

      &.novinka {
        a {
          background: #f9dede;
          color: #c71d1d;

          &:hover {
            background: #c71d1d;
            color: #fff;
          }
        }
      }
    }
  }
}

.product-detail {
  .text {
    .tags {
      @include rem(margin-bottom, 30px);
    }

    .desc {
      @include rem(margin, 30px 0);
    }

    .params {
      @include rem(margin, 30px 0);
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      select {
        font-weight: $font-semibold;
        @include rem(width, 78px);
        @include rem(height, 45px);
        @include rem(padding, 0 10px);
        border: 2px solid #eaeaea;
        border-radius: 7px;
        text-align: center;
      }

      label {
        @include rem(padding-left, 10px);
        color: #373737;
        margin-bottom: 0;
      }
    }

    .price {
      line-height: 1.3em;
      color: $blue;
      font-weight: $font-semibold;
      @include rem(margin, 30px 0);

      .label {
        font-weight: 400;
        width: 100%;
        display: block;
        @include rem(margin-bottom, 10px);
      }

      .val {
        @include rem(font-size, 24px);
      }

      .suffix {
        font-weight: $font-regular;
        white-space: nowrap;
      }

      .more-info {
        font-size: 13px;
        font-weight: $font-regular;
        color: #7f7f7f;
        letter-spacing: -.5px;
      }
    }

    .action {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include rem(margin, 30px 0);

      .compare-link {
        @include rem(font-size, 16px);
        font-weight: $font-semibold;
        color: #000;
        text-decoration: none;
        display: flex;
        align-items: center;

        .icon {
          width: 11px;
          height: 11px;
          flex: 0 0 11px;
          margin-left: 5px;
          position: relative;
          left: 0;
          @include transition(all, .3);
        }

        &:hover {
          text-decoration: underline;

          .icon {
            left: 5px;
          }
        }
      }
    }
  }

  .img {
    .img-main {
      margin-bottom: 10px;

      img {
        width: 100%;
      }
    }

    .img-slider {
      .slider {
        margin: 0 -5px;
      }

      .item {
        padding: 0 5px;
      }
    }

    .inner {
      @include rem(margin-bottom, 30px);
    }
  }

  .manufacturer {
    .item {
      display: flex;
      align-items: center;
    }

    .label {
      margin-right: 30px;
    }

    .logo {
      max-width: 150px;
      padding: 15px;
    }
  }
}

.box-fixer {
  @include rem(margin-top, -240px);
  position: relative;
  z-index: 20;

  &.small-fixer {
    @include rem(margin-top, -14px);
  }

  .fixer {
    padding-top: 15px;
  }
}

.box-negative {
  position: relative;
  z-index: 20;
  @include rem(max-width, 370px);
}

.demand-basket {
  @include rem(padding, 0 0 15px 15px);

  h4 {
    color: $blue;
    margin: 0 0 1rem;
  }

  .item {
    @include rem(margin, 0 0 10px 0);
    position: relative;

    .blue {
      color: $blue;
    }

    .head {
      font-size: 15px;
      font-weight: $font-semibold;
      color: $blue;

      &:not(.mb-auto) {
        margin: 0;
      }

      a {
        text-decoration: none;
        color: $blue;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .close {
      font-size: 32px;
      line-height: 0;
      position: absolute;
      right: 15px;
      //@include rem(top, 5px);
      top: 0;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      color: #000;

      &:hover {
        color: $blue;
      }
    }

    .num {
      @include rem(font-size, 18px);
      position: absolute;
      @include rem(left, -25px);
      @include rem(width, 35px);
      text-align: right;
      top: 0;
      overflow: hidden;
    }
  }

  .foot {
    display: flex;
    justify-content: center;

    .btn {
      width: 100%;
      max-width: 300px;
    }
  }
}

.contact {
  .head {
    @include rem(font-size, 18px);
    font-weight: 600;
    margin: 0;
  }

  h3.medium {
    font-weight: 600;
    color: $blue;
    @include rem(margin-bottom, 40px);
  }

  .contact-list {
    ul {
      display: flex;
      justify-content: center;
      @include rem(margin, 0 -30px);
      flex-wrap: wrap;

      li {
        @include rem(font-size, 42px);
        line-height: 1.3em;
        @include rem(margin, 5px 0);
        @include rem(padding, 0 30px);
        display: flex;
        align-items: center;
        white-space: nowrap;

        a {
          font-weight: $font-light;
          text-decoration: none;
          display: flex;
          align-items: center;
        }

        .icon {
          @include rem(margin-right, 20px);
          @include rem(width, 54px);
          @include rem(height, 54px);
          @include rem(flex, 0 0 54px);
          //background: #e3e3e3;
        }
      }
    }
  }

  .item-list {
    @include rem(margin-bottom, -40px);
  }

  .item {
    @include rem(margin-bottom, 40px);

    ul:last-child {
      margin-bottom: 0;

      li:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.icon-list {
  .item {
    @include rem(margin-bottom, 30px);

    .img {
      border: 2px solid #ddddde;
      color: $blue;
      @include rem(width, 174px);
      @include rem(height, 174px);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto -20px auto;

      .icon {
        @include rem(flex, 0 0 80px);
        @include rem(width, 80px);
        @include rem(height, 80px);
      }
    }

    .text {
      text-align: center;
    }

    h3 {
      background: #fff;
      padding: 0 10px;
      display: inline-block;
    }
  }
}

.col-2-l-set {
  display: flex;
  margin: 0 -15px;

  .column-1 {
    @include rem(flex, 0 0 300px);
    @include rem(max-width, 300px);
    padding: 0 15px;
  }

  .column-2 {
    //flex: 0 0 75%;
    //max-width: 75%;
    padding: 0 15px;
  }
}

.pagebar {
  @include rem(padding-top, 25px);

  ul {
    margin-bottom: 0;
    display: flex;
    justify-content: center;

    li {
      @include rem(padding, 0 6px);
      font-weight: 600;

      a {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border: 2px solid #ddddde;
        @include rem(width, 43px);
        @include rem(height, 43px);
        border-radius: 5px;
        text-decoration: none;
        color: #000;
        @include transition(all, .3);

        &:hover {
          border-color: $blue;
          color: $blue;
        }

        &.active {
          border-color: $yellow;
          cursor: default;
        }
      }
    }
  }
}

.product-nav {
  background: #f4f4f4;

  h3 {
    background: $blue;
    color: #fff;
    @include rem(padding, 15px 25px);
    margin: 0;
  }

  ul {
    margin: 0;
    @include rem(padding-bottom, 15px);
    @include rem(padding-top, 15px);

    li {
      padding: 0;
      margin: 0;

      a {
        display: block;
        @include rem(font-size, 17px);
        line-height: 1.3em;
        font-weight: 600;
        color: $blue;
        text-decoration: none;
        @include rem(padding, 8px 35px 8px 25px);
        position: relative;

        &:hover {
          text-decoration: underline;
        }

        &:before {
          content: '';
          position: absolute;
          right: 15px;
          @include rem(top, 15px);
          width: 8px;
          height: 8px;
          border-left: 2px solid $blue;
          border-bottom: 2px solid $blue;
          transform: rotate(-45deg);
        }
      }

      &.active {
        a {
          &:before {
            transform: rotate(135deg);
            @include rem(top, 18px);
          }
        }

        ul {
          display: block;
        }
      }
    }

    ul {
      @include rem(padding-left, 20px);
      padding-top: 0;
      display: none;

      li {
        a {
          @include rem(font-size, 14px);
          color: #373737;
          @include rem(padding, 8px 15px);

          &:before {
            content: none;
          }
        }

        &.active {
          a {
            color: $blue;
          }
        }
      }
    }
  }
}

.search {
  @include rem(padding, 30px 30px);
  background-color: #f5f5f5;
  margin-bottom: 53px;
  position: relative;
  form {
    display: flex;
    justify-content: space-between;
    @include rem(gap, 30px);

  }
}

//.icon.icon-search {
  //@include rem(width, 26px);
  //@include rem(height, 26px);
  //@include rem(flex, 0 0 26px);
  //position: absolute;
//}

.search-box {
  padding: 0px;
  display: flex;
  background-color: #fff;
  border: solid;
  border-color: #ddddde;
  border-width: 2px;
  border-radius: 5px;
  width: 100%;
  position: relative;

  .icon-search {
    @include rem(width, 26px);
    @include rem(height, 26px);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
 }

  input.text {
    border-color: transparent;
    padding: 5px 15px 5px 45px;
    width: 100%;
    background-color: transparent;
  }
}

.search-buttom {
  flex-shrink: 0;
}

.search-category {
  @include rem(padding, 20px 25px 0px);
  display: flex;

  .search-box {
    .icon-search {
      left: 5px;
   }

    input.text {
      padding: 8px 15px 8px 30px;
    }
  }
}

.product-filter {
  @include rem(margin-bottom, 30px);

  .filter-head {
    flex: 0 0 100px;
    max-width: 100px;
  }

  .filter-bar {
    width: 100%;

    h3 {
      font-weight: 600;
      @include rem(margin, 10px 30px 10px 0);
    }

    .box {
      @include rem(padding, 10px 25px);
      display: flex;
      justify-content: space-between;
    }

    .filter-l {
      display: flex;
    }

    .filter-trigger {
      @include rem(font-size, 18px);
      font-weight: 600;
      text-transform: uppercase;
      @include rem(padding-right, 25px);
      position: relative;
      text-decoration: none;
      display: inline-flex;
      align-items: center;
      @include rem(height, 40px);
      @include rem(margin, 10px 0);
      color: $blue;

      &:before {
        content: '';
        position: absolute;
        right: 0;
        @include rem(top, 13px);
        @include rem(width, 9px);
        @include rem(height, 9px);
        @include rotate(-45deg);
        border-bottom: 2px solid $blue;
        border-left: 2px solid $blue;
        @include transition(all, .3);
      }

      &.active {
        &:before {
          @include rotate(135deg);
          @include rem(top, 18px);
        }
      }
    }

    .filtered-tags {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      ul {
        @include rem(margin, 8px 5px 8px -5px);
        display: flex;
        flex-wrap: wrap;

        li {
          margin: 0;
          @include rem(padding, 2px 5px);

          a {
            @include rem(font-size, 14px);
            color: #373737;
            //border: 2px solid #bebebe;
            border: 2px solid #fad611;
            border-radius: 5px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            @include rem(padding, 0 10px);
            cursor: default;

            .remove {
              cursor: pointer;
              @include rem(width, 10px);
              @include rem(height, 10px);
              @include rem(margin-left, 5px);

              .icon {
                @include rem(width, 10px);
                @include rem(height, 10px);
              }

              &:hover {
                color: $blue;
              }
            }
          }
        }
      }

      .delete {
        @include rem(font-size, 14px);
        color: #373737;
        @include rem(margin, 10px 0);
      }
    }
  }

  .filter-content {
    flex-grow: 1;
    position: relative;

    .content {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 10;
    }

    .foot {
      display: flex;
      justify-content: space-between;
      @include rem(padding-bottom, 30px);
    }
  }

  .block-btn {
    background: $blue;
    color: #fff;
    border: 2px solid $blue;
    text-decoration: none;
    width: 100%;

    &:hover {
      background: $yellow;
      border-color: $yellow;
      color: $blue;
    }

    .txt {
      .icon {
        color: inherit;
      }
    }
  }

  .col {
    flex: 0 0 20%;
    max-width: 20%;
    @include rem(margin-bottom, 20px);
  }
}

.range-slider-wrapper {
  @include rem(padding, 0 80px 0 10px);
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.range-slider {
  width: 100%;

  &.noUi-horizontal {
    height: 4px;
    border: none;
    box-shadow: none;
    background: #dfe0e2;

    .noUi-handle {
      @include rem(width, 18px);
      @include rem(height, 18px);
      @include rem(top, -7px);
      @include rem(right, -9px);
      //@include rem(left, 0);
      border-radius: 50%;
      background: $yellow;
      box-shadow: none;
      cursor: grab !important;
      border: none;

      &:before, &:after {
        content: none;
      }
    }

    .noUi-connect {
      background: $blue;
    }

    .noUi-tooltip {
      @include rem(font-size, 14px);
      font-weight: 600;
      color: $blue;
      background: none;
      border: none;
      padding: 0;
      bottom: -180%;
    }
  }
}

.fix-contact {
  position: fixed;
  z-index: 150;
  right: 0;
  top: 50%;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000;
  @include translate(100%,0);
  @include transition(all, .3);

  .fix-trigger {
    display: flex;
    align-items: center;
    position: absolute;
    top: -45px;
    left: -180px;
    @include rotate(-90deg);
    transform-origin: 100% 100%;
    height: 45px;
    width: 180px;
    padding: 0 20px;
    background: $yellow;
    cursor: pointer;
    @include transition(all, .3);

    .txt {
      display: flex;
      align-items: center;
      padding-left: 20px;
    }

    .img {
      border: 1px solid #fff;
      width: 30px;
      height: 30px;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .icon {
      margin-left: 5px;
      position: relative;
      top: 3px;
    }

    .icon-phone {
      color: #fff;
      @include rotate(90deg);
    }

    .icon-arr-r {
      width: 11px;
      height: 11px;
      margin-left: 14px;
    }

    &:hover {
      background: #274f95;
      color: #fff;
    }
  }

  .fix-content {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5em;
    box-shadow: 0 0 20px 0 rgba(0,0,0,.1);
    padding: 30px;
    background: #fff;

    .head {
      font-weight: 600;
    }

    ul {
      margin: 0;

      li {
        padding: 0;
      }
    }

    a:not(.btn) {
      color: #000;
    }
  }

  &.active {
    @include translate(0,0);
  }
}

.fix-partner {
  @extend .fix-contact;
  top: calc(50% - 180px);
  .fix-trigger {
    background: #e32121;
    color: white;
    justify-content: center;
    white-space: nowrap;
    .txt {
      padding-left: 0;
    }
  }
  .fix-content {
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    p:last-child {
      margin: 0;
    }
  }
}

@media screen and (min-width: 575px) {
  .category-list .item { order: 100; }

  .category-list .item:nth-child(1) { order: 0; }
  .category-list .item-desc:nth-child(2) { order: 2; }
  .category-list .item:nth-child(3) { order: 1; }
  .category-list .item-desc:nth-child(4) { order: 2; }

  .category-list .item:nth-child(5) { order: 3; }
  .category-list .item-desc:nth-child(6) { order: 5; }
  .category-list .item:nth-child(7) { order: 4; }
  .category-list .item-desc:nth-child(8) { order: 5; }

  .category-list .item:nth-child(9) { order: 6; }
  .category-list .item-desc:nth-child(10) { order: 8; }
  .category-list .item:nth-child(11) { order: 7; }
  .category-list .item-desc:nth-child(12) { order: 8; }

  .category-list .item:nth-child(13) { order: 9; }
  .category-list .item-desc:nth-child(14) { order: 11; }
  .category-list .item:nth-child(15) { order: 10; }
  .category-list .item-desc:nth-child(16) { order: 11; }

  .category-list .item:nth-child(17) { order: 12; }
  .category-list .item-desc:nth-child(18) { order: 14; }
  .category-list .item:nth-child(19) { order: 13; }
  .category-list .item-desc:nth-child(20) { order: 14; }

  .category-list .item:nth-child(21) { order: 15; }
  .category-list .item-desc:nth-child(22) { order: 17; }
  .category-list .item:nth-child(23) { order: 16; }
  .category-list .item-desc:nth-child(24) { order: 17; }

  .category-list .item:nth-child(25) { order: 18; }
  .category-list .item-desc:nth-child(26) { order: 20; }
  .category-list .item:nth-child(27) { order: 19; }
  .category-list .item-desc:nth-child(28) { order: 20; }

  .category-list .item:nth-child(29) { order: 21; }
  .category-list .item-desc:nth-child(30) { order: 23; }
  .category-list .item:nth-child(31) { order: 22; }
  .category-list .item-desc:nth-child(32) { order: 23; }

  .category-list .item:nth-child(33) { order: 24; }
  .category-list .item-desc:nth-child(34) { order: 26; }
  .category-list .item:nth-child(35) { order: 25; }
  .category-list .item-desc:nth-child(36) { order: 26; }

  .category-list .item:nth-child(37) { order: 27; }
  .category-list .item-desc:nth-child(38) { order: 29; }
  .category-list .item:nth-child(39) { order: 28; }
  .category-list .item-desc:nth-child(40) { order: 29; }

  .category-list .item:nth-child(41) { order: 30; }
  .category-list .item-desc:nth-child(42) { order: 32; }
  .category-list .item:nth-child(43) { order: 31; }
  .category-list .item-desc:nth-child(44) { order: 32; }
}

@media screen and (min-width: 991px) {
  .category-list .item { order: 100; }

  .category-list .item:nth-child(1) { order: 0; }
  .category-list .item-desc:nth-child(2) { order: 3; }
  .category-list .item:nth-child(3) { order: 1; }
  .category-list .item-desc:nth-child(4) { order: 3; }
  .category-list .item:nth-child(5) { order: 2; }
  .category-list .item-desc:nth-child(6) { order: 3; }

  .category-list .item:nth-child(7) { order: 4; }
  .category-list .item-desc:nth-child(8) { order: 7; }
  .category-list .item:nth-child(9) { order: 5; }
  .category-list .item-desc:nth-child(10) { order: 7; }
  .category-list .item:nth-child(11) { order: 6; }
  .category-list .item-desc:nth-child(12) { order: 7; }

  .category-list .item:nth-child(13) { order: 8; }
  .category-list .item-desc:nth-child(14) { order: 11; }
  .category-list .item:nth-child(15) { order: 9; }
  .category-list .item-desc:nth-child(16) { order: 11; }
  .category-list .item:nth-child(17) { order: 10; }
  .category-list .item-desc:nth-child(18) { order: 11; }

  .category-list .item:nth-child(19) { order: 12; }
  .category-list .item-desc:nth-child(20) { order: 15; }
  .category-list .item:nth-child(21) { order: 13; }
  .category-list .item-desc:nth-child(22) { order: 15; }
  .category-list .item:nth-child(23) { order: 14; }
  .category-list .item-desc:nth-child(24) { order: 15; }

  .category-list .item:nth-child(25) { order: 16; }
  .category-list .item-desc:nth-child(26) { order: 19; }
  .category-list .item:nth-child(27) { order: 17; }
  .category-list .item-desc:nth-child(28) { order: 19; }
  .category-list .item:nth-child(29) { order: 18; }
  .category-list .item-desc:nth-child(30) { order: 19; }

  .category-list .item:nth-child(31) { order: 20; }
  .category-list .item-desc:nth-child(32) { order: 23; }
  .category-list .item:nth-child(33) { order: 21; }
  .category-list .item-desc:nth-child(34) { order: 23; }
  .category-list .item:nth-child(35) { order: 22; }
  .category-list .item-desc:nth-child(36) { order: 23; }

  .category-list .item:nth-child(37) { order: 24; }
  .category-list .item-desc:nth-child(38) { order: 27; }
  .category-list .item:nth-child(39) { order: 25; }
  .category-list .item-desc:nth-child(40) { order: 27; }
  .category-list .item:nth-child(41) { order: 26; }
  .category-list .item-desc:nth-child(42) { order: 27; }

  .category-list .item:nth-child(43) { order: 28; }
  .category-list .item-desc:nth-child(44) { order: 31; }
  .category-list .item:nth-child(45) { order: 29; }
  .category-list .item-desc:nth-child(46) { order: 31; }
  .category-list .item:nth-child(47) { order: 30; }
  .category-list .item-desc:nth-child(48) { order: 31; }
}

@keyframes showDropdown {
  0% {
    display: none;
    @include translate(0,-100%);
  }
  5% {
    display: block;
    @include translate(0,-100%);
  }
  100% {
    display: block;
    @include translate(0,0);
  }
}

@keyframes showSearch {
  0% {
    display: none;
    max-width: 0;
  }
  5% {
    display: block;
    max-width: 0;
  }
  100% {
    display: block;
    max-width: 100%;
  }
}

form #contact_form_website, #contact_form_email {
  display: none;
}
