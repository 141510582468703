/************************************************************************************
HEADER
*************************************************************************************/

.header {
  width: 100%;
  background: #fff;

  .h_1 {
    position: relative;
    z-index: 100;
    background: #fff;
    margin-bottom: 0;
    @include transition(all, .3);

    .inner {
      @include rem(height, 170px);
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .h_2 {
    position: relative;

    .megamenu {
      background: #f8f8f8;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 200;
      display: none;

      &.activated {
        display: block;
      }
    }
  }

  .header-r {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;
    flex-grow: 0;
    flex-shrink: 1;
  }

  .logo {
    @include rem(width, 110px);
    flex-shrink: 0;
    flex-grow: 0;
  }
}

.megamenu {
  font-size: 16px;
  box-shadow: 0 15px 15px 0 rgba(0,0,0,.2);

  .m_1 {
    @include rem(padding, 40px 0);
  }

  .m_2 {
    border-top: 1px solid #ddddde;
    text-align: center;
  }

  .more-link {
    font-size: 16px;
    font-weight: $font-semibold;
    display: flex;
    align-items: center;
    justify-content: center;
    @include rem(padding, 15px 0);
    text-decoration: none;
    color: #000;

    &:hover {
      text-decoration: underline;
    }

    .icon {
      margin-left: 5px;
    }
  }

  .entry {
    ul {
      li {
        &:before {
          width: 4px;
          height: 4px;
          @include rem(top, 10px);
        }
      }
    }
  }

  .col-item {
    @include rem(padding, 20px 0);
  }

  ul {
    li {
      margin: 0;
      line-height: 1.5em;

      a {
        color: #505050;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.dropdown-search {
  position: absolute;
  z-index: 80;
  left: 0;
  top: 0;
  width: 100%;
  background: #f8f8f8;
  box-shadow: 0 15px 15px 0 rgba(0,0,0,.2);
  display: none;
}

.dropdown-search, .search-results {
  &.active {
    display: block;
    animation: showDropdown .5s;
  }

  &.dropdown-search-category, &.dropdown-search-box {
    opacity: 0;
    @include transition(opacity, .1);
    display:block;
    height: 0;
    pointer-events: none;

    &.active {
      display: block;
      animation:none;
      opacity: 1;
      height: auto;
      pointer-events: all;
      @include transition(opacity, .3);
    }
  }

  &.dropdown-search-box {
    top: calc(10px + 100%);
  }

  .dropdown-inner {
    @include rem(padding, 40px 0);
    margin: 0 auto;
  }

  .section-search {
    @include rem(margin-bottom, 30px);

    &:last-child {
      margin-bottom: 0;
    }
  }

  .section-head {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;

    .head-l {
      display: flex;
      align-items: flex-end;
    }

    .head {
      @include rem(font-size, 32px);
      font-weight: 600;
      line-height: 1.3em;
      margin-bottom: 0;
      padding-right: 10px;
      color: $blue;
    }

    .num {
      font-size: 15px;
    }

    .more {
      font-size: 15px;
      font-weight: 700;
    }
  }

  .item-inner {
    display: flex;
  }

  .foot {
    display: flex;
    justify-content: center;
  }
}

.search-results {
  &.active {
    display: block;
    position: relative;
    background: none;
    box-shadow: none;
  }
}

.main-nav {
  vertical-align: top;
  text-align: left;
  @include rem(padding-top, 10px);
  height: 50%;
  width: 100%;

  ul {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    @include rem(margin, 0 -15px);
    height: 100%;

    li {
      @include rem(font-size, 21px);
      font-weight: $font-semibold;
      @include rem(padding, 0 15px);
      margin: 0;
      display: flex;
      height: 100%;

      a {
        color: $blue;
        text-decoration: none;
        position: relative;
        white-space: nowrap;
        @include transition(all, .2);
        position: relative;

        &:hover,
        &.activated {
          border-color: $blue;

          &:after {
            width: 100%;
            @include transition(all, .2);
          }
        }

        &:after {
          content: '';
          width: 0;
          height: 4px;
          background: $blue;
          position: absolute;
          left: 50%;
          bottom: 0;
          @include translate(-50%,0);
        }
      }
    }
  }
}

.secondary-nav {
  //width: 100%;
  height: 50%;
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  @include rem(padding-bottom, 10px);

  ul {
    align-self: flex-end;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include rem(margin, 0 -15px);

    li {
      line-height: 1.3em;
      font-weight: $font-medium;
      margin: 0;
      @include rem(padding, 0 10px);
      flex-grow: 1;

      a {
        color: #505050;
        text-decoration: none;
        display: flex;
        align-items: center;

        &:hover {
          color: $blue;
        }
      }

      .icon {
        @include rem(width, 26px);
        @include rem(height, 26px);
        @include rem(flex, 0 0 26px);
        @include rem(margin-right, 5px);
        //background: #e3e3e3;
        color: #505050;
      }

      &.li-search-trig {
        display: none;

        a {
          //background: $blue;
        }
      }

      &.li-flag {
        img {
          max-width: 30px;
          height: auto;
          box-shadow: 0 0 3px 0 rgba(0,0,0,.1);
        }
      }
    }
  }
}

.lng {
  position: relative;
  display: block;

  img {
    display: block;
    max-width: 30px;
    height: auto;
    box-shadow: 0 0 3px 0 rgba(0,0,0,.1);
    border-radius: 3px;
  }

  .current {
    display: block;
    @include rem(padding-right, 25px);
    cursor: pointer;

    &:hover {
      color: $blue;
    }

    /*.icon-caret {
      position: absolute;
      right: 0;
      top: 50%;
      @include translate(0,-50%);
    }*/

    &:before {
      content: '';
      position: absolute;
      display: block;
      @include rem(right, 2px);
      top: 50%;
      @include rem(width, 10px);
      @include rem(height, 10px);
      @include rem(margin-top, -7px);
      border-left: 2px solid #505050;
      border-bottom: 2px solid #505050;
      @include rem(border-width, 1.85px);
      transform: rotate(-45deg);
      transform-origin: 50% 50%;
      @include transition(all, .3);
    }
  }

  ul {
    display: none;
    margin: 0;
    position: absolute;
    left: 0;
    @include rem(left, -5px);
    @include rem(top, 30px);
    z-index: 10;
    background: $blue;
    //background: $white;
    box-shadow: 0 0 5px 0 rgba(0,0,0,.2);
    box-shadow: 0 10px 15px rgba($black, 0.3);
    @include rem(padding, 6px);
    border-radius: 5px;

    li {
      @include rem(font-size, 16px);
      @include rem(margin-bottom, 7px);
      padding: 0;

      &:last-child {
        margin-bottom: 0;
      }

      img {
        box-shadow: 0 0 3px 0 rgba(0,0,0,.25);
      }

      a {
        color: #fff !important;
      }
    }
  }

  &.active {
    ul {
      display: block;
    }

    .current {
      &:before {
        transform: rotate(135deg);
       @include rem(margin-top, -1px);
      }
    }
  }
}

.teaser {
  background: $blue;
  color: #fff;
  //@include rem(padding, 120px 0);
  @include rem(padding, 55px 0);
  position: relative;
  overflow: hidden;

  h1 {
    color: #fff;
    margin: 0;
  }

  & > * {
    position: relative;
    z-index: 10;
  }

  .teaser-img {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 5;
  }

  // Temp logo in teaser
  .teaser-logo-float {
    position: absolute;
    right: 0;
    top: 50%;
    @include rem(width, 140px);
    @include rem(height, 140px);
    @include rem(margin-top, -70px);
  }

  &.teaser-detail {
    @include rem(padding, 60px 0);

    .breadcrumbs {
      padding-bottom: 0;

      a {
        color: $yellow;
      }

      .pipe {
        color: #8193b1;
      }
    }
  }
}

// Temp logo in teaser
.teaser-logo-float {
  position: absolute;
  right: 0;
  top: 50%;
  @include rem(width, 140px);
  @include rem(height, 140px);
  @include rem(margin-top, -70px);
}


.breadcrumbs {
  @include rem(font-size, 18px);
  @include rem(padding, 15px 0);
  border-bottom: 2px solid #f5f5f5;

  a {
    font-weight: $font-semibold;
  }

  .pipe {
    color: #c7c7c7;
  }

  &.alt {
    border: none;
  }
}

.nav-btn {
  display: none;
  vertical-align: top;
  width: 30px;
  height: 30px;
  text-align: center;
  position: relative;
  z-index: 2001;
  cursor: pointer;
  align-items: center;
  margin-left: 20px;

  .lines {
    width: 30px;
    height: 4px;
    flex: 0 0 30px;
    display: block;
    position: relative;
    position: absolute;
    top: 50%;
    margin-top: -2px;
  }

  .line-1,
  .line-2,
  .line-3 {
    display: block;
    width: 100%;
    height: 2px;
    background: $blue;
    transition: 0.1s;
    position: absolute;
    left: 0;
    content: '';
    transform-origin: 0.28571rem center;
  }

  .line-1 {
    top: 0;
    transition: opacity 0.1s 0.1s ease;
  }

  .line-2 {
    top: 10px;
    transform-origin: 50% 50%;
    transition: top 0.1s 0.1s ease, -webkit-transform 0.1s ease;
    transition: top 0.1s 0.1s ease, transform 0.1s ease;
  }

  .line-3 {
    top: -10px;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    transition: top 0.1s 0.1s ease, -webkit-transform 0.1s ease;
    transition: top 0.1s 0.1s ease, transform 0.1s ease;
  }

  &.active {
    .line-1 {
      transition: opacity 0.1s 0s ease;
      background: transparent;
      opacity: 0;
    }

    .line-2 {
      transform: rotate3d(0, 0, 1, 45deg);
      transition: top 0.1s ease, transform 0.1s 0.1s ease;
      top: 0;
    }

    .line-3 {
      transform: rotate3d(0, 0, 1, -45deg);
      transition: top 0.1s ease, transform 0.1s 0.1s ease;
      top: 0;
    }

    &:hover {
      .line-1,
      .line-2,
      .line-3 {
          background: $blue;
      }
    }
  }
}

.mobile-nav {
  background: $blue;
  width: 100%;
  position: fixed;
  right: 0;
  top: -5px;
  z-index: 90;
  overflow-y: auto;
  max-height: 100%;
  @include rem(padding, 140px 0 20px 0);
  @include transition(all, .3);
  @include translate(0,-100%);

  &.active {
    @include translate(0,0);
  }

  ul {
    font-size: 17px;
    margin: 0;
    padding: 0;

    li {
      color: #fff;
      margin: 0;
      padding: 0;
      background: none;
      border: none;
      a {
        font-weight: 400;
        display: block;
        width: 100%;
        padding: 14px 0;
        color: #fff;
        border: none;
        position: relative;
        text-decoration: none;
        border-bottom: 1px solid rgba(255,255,255,.1);

        .more {
          position: absolute;
          right: 0;
          top: 0;
          width: 50px;
          height: 100%;
          display: inline-flex;
          justify-content: center;
          align-items: center;

          &:before {
            content: '';
            position: absolute;
            display: block;
            right: 5px;
            top: 50%;
            @include rem(width, 15px);
            @include rem(height, 15px);
            @include rem(margin-top, -12px);
            border-left: 2px solid #fff;
            border-bottom: 2px solid #fff;
            transform: rotate(-45deg);
            transform-origin: 50% 50%;
            @include transition(all, .3);
          }
        }
      }

      ul {
        @include rem(padding-left, 30px);
        background: #20478a;
        display: none;

        &.sub-1 {
          font-size: 16px;
          padding: 10px 0;
          margin: 0 -15px;
          position: relative;

          li {

          }

          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 5px;
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,7db9e8+100&0.5+0,0+100 */
            background: -moz-linear-gradient(top,  rgba(0,0,0,0.1) 0%, rgba(125,185,232,0) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top,  rgba(0,0,0,0.1) 0%,rgba(125,185,232,0) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom,  rgba(0,0,0,0.1) 0%,rgba(125,185,232,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          }
        }

        &.sub-2 {
          font-size: 16px;
          padding: 0;

          li {
            //margin-left: 30px;
            padding-left: 30px;
          }
        }
      }

      &.active {
        & > a {
          color: #fff;
          border: none;
        }

        & > ul {
          display: block;
        }

        & > a > .more {
          &:before {
            @include rem(margin-top, -4px);
            transform: rotate(135deg);
          }
        }
      }
    }
  }

  form.form-search {
    display: none;
    width: 100%;

    .cell {
      max-width: 100%;
    }
  }
}

.mobile-nav-overlay {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 80;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.9);
  display: none;
}

.mobile-nav-wrap {
  display: none;
}

@keyframes rotateMenu {
  0% {
    transform: rotateX(-90deg)
  }
  70% {
    transform: rotateX(20deg)
  }
  100% {
    transform: rotateX(0deg)
  }
}
