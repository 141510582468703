/**
 * Colors
 */
$black: #000;
$white: #fff;
$yellow: #fad611;
$blue: #274f95;
$blueDark: #1a3364;
// Colors
$orange: #ff4e00;
$blue-dark: #071529;
$gray-100: #f9f9f9 !default;
$gray-500: #9ea1a5;

/**
/* Font weight variables
 */
$font-light: 100;
$font-regular: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;

// Navbar
$navbar-height-mobile: 90px;

// Topnavbar
$topnavbar-height: 32px;

